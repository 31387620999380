<template>
  <div class='homePage-wrap'>
    <!-- 头部 -->
    <common-header :left-show='true' :comment='{statusShow: false}'></common-header>
    <!-- 主体 -->
    <div class='homePage-main'>
      <!-- 功能列表 -->
      <div class='funct-wrap flex'>
        <div class='func-body' v-for='(el, i) in funcList' :key='i' :style='{ background: el.bg }'
             @click='funcHandler(el.key)'>
          <div class='title PFMC'>{{ el.title }}</div>
          <div class='content1 PFRC'>{{ el.content1 }}</div>
          <div class='content2 PFRC'>{{ el.content2 }}</div>
          <div class='handle-wrap flex'>
            <div class='handle-txt PFRC'>{{ el.handle }}</div>
            <img src='@/assets/homePage/rightArrow.png' alt=''>
          </div>
          <img class='picture' :src='el.picture' alt=''>
        </div>
      </div>
      <!-- 课堂列表 -->
      <div class='course-wrap'>
        <div class='title-wrap flex'>
          <div class='txt PFMC'>同步课堂</div>
          <img src='@/assets/homePage/play.png' alt=''>
        </div>
        <div class='course-list flex' v-loading='loading' element-loading-text='数据加载中...'
             element-loading-background='rgba(0, 0, 0, 0.5)' v-infinite-scroll='loadDatas'>
          <div class='course-body' @click='goToVideo(el)' v-for='(el, i) in courseDatas' :key='i'>
            <div class='title-box flex'>
              <div class='txt PFMC'>{{ el.liveName || '- -' }}</div>
              <img :src='liveIcon(el.liveStatus)' alt=''>
            </div>
            <div class='line-gap'></div>
            <div class='info-wrap flex'>
              <div class='user-wrap flex'>
                <div :class='["name","PFRC",el.teacherName?.length>=4?"nameCss":""]'
                     :title='el.teacherName?.length>=4?el.teacherName:""'>{{ el.teacherName || '- -' }}
                </div>
                <div class='vertical-gap'></div>
                <div class='school PFRC newCss' :title='el.schoolName'>{{ el.schoolName || '- -' }}</div>
              </div>
              <div class='live-status PFRC' :style="{ color: el.liveStatus == 1 ? '#1FCC9C' : '#9A9CA1' }">{{ el.time }}
              </div>
            </div>
          </div>
          <div v-if='andMore' class='andMore'>加载中...</div>
        </div>
      </div>
    </div>
    <!-- 提示有一堂课的弹框： -->
    <my-dialog class='dialog-wrap' width='382px' pLR='0' borderRadius='8px' :showMaskFlag='true'
               :dialogFlag='dialogForHaveCourse' @hideMask='dialogForHaveCourse = false'>
      <div class='dialog_inner_container_for_have_course'>
        <div class='dialog_inner_container_for_have_course_header'>系统提示</div>
        <div class='dialog_inner_container_for_have_course_body'>
          <img class='dialog_inner_container_for_have_course_body_img'
               src='https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/72bffef3700d4675b001d70a8cf39e7a.png'
               alt=''>
          <p class='dialog_inner_container_for_have_course_body_title'>
            您有一门课正在进行中，结束后才可开始新课
          </p>
          <p class='dialog_inner_container_for_have_course_body_btn' @click='goToOfHaveCourse'>
            进入课堂
          </p>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import commonHeader from 'components/common/commonHeader.vue'
import toolsForDate from '@/utils/toolsForDate'
import myDialog from '../../components/common/myDialog.vue'

export default {
  name: 'Home',
  components: {
    commonHeader,
    myDialog
  },
  data() {
    return {
      funcList: [
        {
          title: '加入直播',
          content1: '输入参加码，',
          content2: '使用网页版加入直播',
          handle: '立即加入',
          key: 'addLive',
          bg: 'linear-gradient(180deg, #E87071 0%, #F1AE98 100%)',
          picture: require('@/assets/homePage/addLive.png')
        },
        {
          title: '发起直播',
          content1: '支持教室直播、多教室互动',
          content2: '',
          handle: '立即发起',
          key: 'launchLive',
          bg: 'linear-gradient(180deg, #647BD1 0%, #8B66DF 100%)',
          picture: require('@/assets/homePage/launchLive.png')
        },
        {
          title: '体验AI剪辑',
          content1: 'AI主题切片、AI文字提取',
          content2: '',
          handle: '立即体验',
          key: 'aiClip',
          bg: 'linear-gradient(180deg, #1672FF 0%, #71A9FF 100%)',
          picture: require('@/assets/homePage/aiExperience.png')
        },
        {
          title: '申请开通',
          content1: '支持多种类型教室接入',
          content2: '',
          handle: '立即申请',
          key: 'applyOpen',
          bg: 'linear-gradient(180deg, #28B4AF 0%, #A6FFFE 100%)',
          picture: require('@/assets/homePage/applyOpen.png')
        }
      ],
      courseDatas: [],
      liveIcon: (status) => {
        return status == 1 ? require('@/assets/homePage/liveicon.png') : require('@/assets/homePage/bofang.png')
      },
      loading: false,
      andMore: false,
      hasMore: false, // 列表有没有更多数据
      pageNum: 1,
      pageSize: 10,
      totalCount: 0,
      goToOfHaveCourseType: '',
      goToOfHaveCourseOfLiveId: '',
      dialogForHaveCourse: false,
      rtData: {}
    }
  },
  mounted() {
    this.getCourseList()
  },
  methods: {
    async getCourseList () {
      this.loading = true
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      const res = await this.$http.courseList(params)

      this.loading = false
      if (res.data.code !== 200) {
        // this.loading = false
        return this.$message.error(res.data.msg || '课堂列表请求失败')
      }

      if (res.data.rt && res.data.rt.list.length) {
        res.data.rt.list.map(v => {
          const liveDate = v.actualStartTime?.split(' ')[0] || ''
          const liveTime = v.actualStartTime?.split(' ')[1] || ''
          const howDays = toolsForDate.getDateMinus(liveDate)
          v.time = v.liveStatus == 1 ? '正在直播…' : ((howDays == 0 ? '今天' : (howDays == -1 ? '昨天' : liveDate.slice(5).replace('-', '月') + '日')) + ' ' + liveTime.slice(0, 5).trim() ? (howDays == 0 ? '今天' : (howDays == -1 ? '昨天' : liveDate.slice(5).replace('-', '月') + '日')) + ' ' + liveTime.slice(0, 5) : '时间待定')
        })
      }
      this.totalCount = res.data.rt?.totalCount
      // this.loading = false
      this.courseDatas = [ ...this.courseDatas, ...res.data.rt?.list ?? []]
      if (this.totalCount > this.pageSize * this.pageNum) {
        this.hasMore = true
        this.pageNum += 1
      } else {
        this.hasMore = false
      }
    },
    async loadDatas () {
      const { loading, hasMore } = this
      if (!hasMore || loading) return
      this.andMore = true
      await this.getCourseList()
      this.andMore = false
    },
    // async loadDatas () {
    //   if (this.totalCount > this.pageSize * this.pageNum) {
    //     this.andMore = true
    //     this.pageSize += 10
    //     await this.getCourseList()
    //     this.andMore = false
    //   }
    // },
    operationForGoNewPage (pageName) {
      this.$router.push({ name: pageName })
    },
    async operationForTestAxios() {
      try {
        const { data: result } = await this.$http.getAdminUserSchool()
        console.log(result, 'result')
      } catch (err) {
        console.log(err)
      }
    },
    funcHandler(key) {
      switch (key) {
        case 'addLive':
          this.addLiveFunc()
          break
        case 'launchLive':
          this.launchLiveFunc()
          break
        case 'aiClip':
          this.aiClipFunc()
          break
        case 'applyOpen':
          this.applyOpenFunc()
          break
      }
    },
    addLiveFunc() {
      window.open('https://www.livecourse.com/teacher/Home?typeForCreate=join', '_self')
    },
    async launchLiveFunc() {
      if (!localStorage.getItem('User_userInfo')) {
        let resultUrl = location.href.split('?')[0]
        resultUrl = resultUrl.lastIndexOf('/') === resultUrl.length - 1 ? resultUrl.slice(0, resultUrl.lastIndexOf('/')) : resultUrl
        location.href = `https://www.livecourse.com/api/simple-class/zhs/redirectUrlAndGetUserInfo?redirectUrl=${resultUrl}`
      } else {
        // authType 1学校 2企业 3培训机构   roleList 学校<1学生 2老师> 企业<1员工 2老师 3管理员>
        if (!JSON.parse(localStorage.getItem('User_userInfo')).authInfo) return this.$message.error('拥有老师权限才能发起直播，请联系管理员开通！')
        const authTypeItemForSchool = JSON.parse(localStorage.getItem('User_userInfo')).authInfo.find(item => item.authType === 1)
        if (!authTypeItemForSchool) this.$message.error('拥有老师权限才能发起直播，请联系管理员开通！')
        if (!(authTypeItemForSchool.roleList || []).includes(2)) return this.$message.error('拥有老师权限才能发起直播，请联系管理员开通！')
        this.$loading.show()
        const { data } = await this.$http.teacherQuickLive()
        this.$loading.hide()
        if (data.code !== 200) return this.$message.error(data.msg)
        const { rt } = data
        if (rt.resultStatus !== 1 && rt.resultStatus !== 2) return this.$message.error(rt.resultMessage)
        this.rtData = rt
        switch (rt.resultStatus) {
          case 1: // 创建直播成功
            window.open(`https://www.livecourse.com/teacher/StudentPicture?liveId=${rt.liveId}&sourceofcome=sourcefromweb`, '_blank')
            break
          case 2: // 您有一门正在进行中的课
            if (rt.role === 1) {
              if (Object.prototype.hasOwnProperty.call(rt, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLEONEFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLEONE'
              }
            } else if (rt.role === 2) {
              if (Object.prototype.hasOwnProperty.call(rt, 'liveCourseId')) {
                // this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLETWOFORLC'
                this.$message.warning('抱歉，当前直播模式不支持此方式进入，请用APP扫码投屏')
              } else {
                this.goToOfHaveCourseType = 'HAVECOURSEFORCREATEOFROLETWO'
              }
            }
            this.dialogForHaveCourse = true
            this.goToOfHaveCourseOfLiveId = rt.liveId
            break
        }
      }
    },
    aiClipFunc() {
      this.$router.push('/AIExperience')
    },
    applyOpenFunc() {
      // this.$router.push('/applyOpen')
      window.open('http://www.livecourse.com/4vcIhcJt/pcHome', '_blank')
    },
    goToOfHaveCourse() {
      let name = ''
      switch (this.goToOfHaveCourseType) {
        case 'HAVECOURSEFORCREATEOFROLEONE':
        case 'HAVECOURSEFORJOINOFROLEONE':
          name = 'StudentPicture'
          break
        case 'HAVECOURSEFORCREATEOFROLETWO':
        case 'HAVECOURSEFORJOINOFROLETWO':
          name = 'TeacherPicture'
          break
        case 'HAVECOURSEFORJOINOFROLEONEFORLC':
          name = 'StudentPictureForLC'
          break
        case 'HAVECOURSEFORJOINOFROLETWOFORLC':
          name = 'TeacherPictureForLC'
          break
      }
      //产品要求跳转前关闭 还有一节课的提示
      this.dialogForHaveCourse = false
      setTimeout(() => {
        window.open(`https://www.livecourse.com/teacher/${name}?liveId=${this.goToOfHaveCourseOfLiveId}&sourceofcome=sourcefromweb`, '_blank')
      }, 400)
    },
    goToVideo(item) {
      // 直播回放直接进入，正在直播已登录可以进，未登录跳转到登录页
      if (item.liveStatus == 1) { // 正在直播
        if (localStorage.getItem('User_userInfo')) {
          const uuid = JSON.parse(localStorage.getItem('User_userInfo')).uuid || ''
          // 加入直播前先 +1
          const params = {
            studentInviteCode: item.studentInviteCode || '',
            userName: JSON.parse(localStorage.getItem('User_userInfo')).realname || '',
            uuid
          }
          this.$http.appJoinLive(params)
          // 加入直播
          window.open(`https://www.livecourse.com/teacher/IndividualStudent?liveId=${item.liveId}&uuid=${uuid}`, '_self')
        } else {
          let resultUrl = location.href
          location.href = `https://www.livecourse.com/api/simple-class/zhs/redirectUrlAndGetUserInfo?redirectUrl=${resultUrl}`
        }
      } else { // 直播回放
        const url = `https://tp.zhihuishu.com/#/playback?role=0&id=${item.liveId}`
        window.open(url, "_blank")
      }
    }
  }
}
</script>

<style lang='less' scoped>
.homePage-wrap {
  width: 100%;
  height: 100vh;
  background: url('https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/60a62ca4894f4698a8beabab03130d7e.png') no-repeat;
  background-size: cover;
  position: fixed;
  overflow: auto;

  .homePage-main {
    width: 1280px;
    min-width: 1280px;
    margin: 60px auto 0;
    box-sizing: border-box;

    // 功能列表
    .funct-wrap {
      justify-content: space-between;

      .func-body {
        width: 308px;
        height: 157px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 6px 13px 6px rgba(0, 5, 18, 0.6000);
          transform: translateY(-4px);
          transition: all .1s cubic-bezier(.02, .01, .47, 1);
        }

        .title {
          font-size: 24px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 1;
          position: absolute;
          left: 16px;
          top: 20px;
        }

        .content1,
        .content2 {
          font-size: 14px;
          font-weight: 400;
          color: #FFFDFB;
          line-height: 1;
          position: absolute;
          left: 16px;
        }

        .content1 {
          top: 57px;
        }

        .content2 {
          top: 76px;
        }

        .handle-wrap {
          align-items: center;
          position: absolute;
          left: 16px;
          top: 121px;

          .handle-txt {
            font-size: 16px;
            font-weight: 400;
            color: #FFFDFB;
            line-height: 1;
            margin-right: 4px;
          }

          img {
            width: 16px;
            height: 16px;
          }
        }

        .picture {
          width: 159px;
          height: 157px;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    // 同步课堂
    .course-wrap {
      margin-top: 60px;

      // background-color: pink;
      .title-wrap {
        align-items: center;

        .txt {
          font-size: 28px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 1;
          margin-right: 8px;
        }

        img {
          width: 32px;
          height: 32px;
        }
      }

      // 课程列表
      .course-list {
        margin-top: 32px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .course-body {
          width: 624px;
          height: 114px;
          box-sizing: border-box;
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 0.1);
          padding: 17px 32px 20px 20px;
          margin-bottom: 32px;
          flex-shrink: 0;
          cursor: pointer;

          &:hover {
            box-shadow: 0px 6px 13px 6px rgba(0, 5, 18, 0.6000);
            transform: translateY(-4px);
            transition: all .1s cubic-bezier(.02, .01, .47, 1);
          }

          .title-box {
            justify-content: space-between;
            align-items: center;

            .txt {
              font-size: 18px;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 18px;
              width: 100%;
              margin-right: 16px;
              display: inline-block;
              overflow: hidden;
              word-break: keep-all;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            img {
              width: 24px;
              height: 24px;
            }
          }

          .line-gap {
            width: 100%;
            height: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            margin: 19px 0;
          }

          .info-wrap {
            justify-content: space-between;
            align-items: center;

            .user-wrap {
              align-items: center;

              .name,
              .school {
                font-size: 16px;
                font-weight: 400;
                color: #9A9CA1;
                line-height: 16px;
              }


              .vertical-gap {
                width: 1px;
                height: 12px;
                background: #D8D8D8;
                border-radius: 1px;
                border: 1px solid #979797;
                margin: 0 8px;
              }
            }

            .live-status {
              font-size: 16px;
              font-weight: 400;
              line-height: 16px;
            }
          }
        }
      }

      .andMore {
        width: 100%;
        color: #fff;
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }
}

// 课程进行中
.dialog_inner_container_for_have_course {
  padding: 20px 17px;
  padding-bottom: 25px;

  .dialog_inner_container_for_have_course_header {
    width: 100%;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1E2228;
  }

  .dialog_inner_container_for_have_course_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialog_inner_container_for_have_course_body_img {
      width: 150px;
      height: 105px;
      display: block;
      margin-top: 20px;
    }

    .dialog_inner_container_for_have_course_body_title {
      width: 232px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2A2A2A;
      line-height: 20px;
      margin-top: 32px;
    }

    .dialog_inner_container_for_have_course_body_btn {
      width: 104px;
      height: 40px;
      background: #1672FF;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;
      user-select: none;

      &:hover {
        background: #1367e6;
      }

      &:active {
        background: #1672FF;
      }
    }
  }
}

//新增样式 限制名字各个长度 超出出现省略号
.newCss {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 320px;
}

.nameCss {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #9A9CA1;
  line-height: 16px;
}
</style>
